#ticket-sort-popover {
  .ant-popover-content {
    width: 400px;
  }
}
.ant-typography.filter-fields {
  cursor: pointer;
  line-height: 25px;
  font-size: var(--font-size-medium) !important;
}
