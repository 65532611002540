.header-wrapper {
  background: var(--color-gradient-dark);

  padding: 0;
  line-height: initial;
  height: 200px;

  border-radius: 0 0 0 80px;
  transition: height 0.5s ease-in-out 0.1s;

  &.collapse {
    height: 50px;
    border-radius: 0;

    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    z-index: 100;
    &.mt {
      top: 38px;
    }

    & .header .header-left {
      left: unset;
      transform: translateX(0%);
    }
  }

  .header {
    height: var(--header-height);
    padding-left: 50px;
    padding-right: 20px;
    justify-content: space-between;
    position: relative;
    z-index: 9999;

    .header-left {
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      transition: transform 0.8s ease-in-out;

      .header-logo-icon {
        animation: fadeIn 1s;
      }

      img {
        cursor: pointer;
        width: var(--header-logo-width);
        filter: brightness(0) invert(1);
      }

      .header-menu-list {
        list-style: none;
        display: flex;
        align-items: baseline;
        justify-content: flex-start;

        .header-menu-item {
          display: block;
          color: #fff;
          position: relative;
          text-decoration: none;
          height: 30px;
          line-height: 30px;
          padding: 0 30px;
          min-width: 110px;
          font-weight: var(--font-weight);
          font-size: var(--font-size-medium);
          cursor: pointer;

          &.actived {
            &::after {
              width: 100%;
            }
          }

          &::after {
            content: '';
            position: absolute;
            left: 0;
            width: 0;
            bottom: 0;
            // width: 90%;
            height: 0.15em;
            background-color: #fff;
            opacity: 1;
            transition: width 300ms, transform 300ms;
          }

          &:hover::after,
          &:focus::after {
            width: 100%;
          }

          .menu-dropdown {
            min-width: 100%;
            // padding: 15px 0;
            top: 40px;
            left: 0;
            box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
            position: absolute;
            background: #fff;
            z-index: 100;
            transition: 0.5s padding, 0.5s background;

            &::before {
              position: absolute;
              content: '';
              left: 0;
              top: -10px;
              width: 100%;
              height: 20px;
              background-color: transparent;
            }

            & li {
              padding: 0 36px 0 19px;
              height: 54px;
              line-height: 54px;
              color: #414040;
            }

            & li:hover {
              background: var(--color-gradient-light);
            }

            .header-menu-sub-item {
              &.actived {
                background: var(--color-gradient-normal);
              }
            }
          }

          .menu-dropdown > * {
            overflow: hidden;
            height: 30px;
            padding: 10px 10px;
            background: rgba(0, 0, 0, 0);
            white-space: nowrap;
            transition: 0.5s height cubic-bezier(0.73, 0.32, 0.34, 1.5),
              0.5s padding cubic-bezier(0.73, 0.32, 0.34, 1.5), 0.5s margin cubic-bezier(0.73, 0.32, 0.34, 1.5),
              0.5s 0.2s color, 0.2s background-color;
          }

          &:not(:hover) > .menu-dropdown {
            padding: 0px 0;
          }

          &:not(:hover) > .menu-dropdown > * {
            visibility: hidden;
            height: 0;
            padding-top: 0;
            padding-bottom: 0;
            margin: 0;
            color: rgba(25, 25, 25, 0);
            transition: 0.5s 0.1s height, 0.5s 0.1s padding, 0.5s 0.1s margin, 0.3s color, 0.6s visibility;
            z-index: 99;
          }
        }
      }
    }

    .header-right {
      margin-left: auto;

      .header-menu-btn {
        @keyframes shiny-glass {
          0% {
            -webkit-transform: scale(0) rotate(45deg);
            opacity: 0;
          }

          80% {
            -webkit-transform: scale(0) rotate(45deg);
            opacity: 0.5;
          }

          81% {
            -webkit-transform: scale(4) rotate(45deg);
            opacity: 1;
          }

          100% {
            -webkit-transform: scale(50) rotate(45deg);
            opacity: 0;
          }
        }

        position: relative;
        display: flex;
        justify-content: center;
        background-color: rgba(255, 255, 255, 0.2);
        color: #fff;
        gap: 10px;
        padding: 16px;
        margin-right: 20px;
        border: none;
        align-items: center;
        border-radius: var(--radius-normal);
        overflow: hidden;

        &:before {
          position: absolute;
          content: '';
          display: inline-block;
          top: -180px;
          left: 0;
          width: 30px;
          height: 100%;
          background-color: #fff;
          animation: shiny-glass 3.5s ease-in-out infinite;
        }

        .header-menu-btn-icon {
          // width: 20px;
          height: 18px;
        }

        span {
          color: var(--color-button);
          font-size: var(--font-size-medium);
          font-weight: var(--font-weight-normal);
          line-height: 1.5;
        }
      }

      .user-profile {
        height: 32px;
        display: flex;
        background: rgba(255, 255, 255, 0.2);
        border-radius: var(--radius-normal);
        padding-inline: 8px;

        color: #fff;
        cursor: pointer;
      }
    }

    // Override css
    .ant-row {
      flex-flow: row nowrap;
    }
  }
}

.user-profile-item {
  padding: 10px;
}
