.notification-icon {
  width: 32px;
  height: 32px;
  display: flex;
  background: rgba(255, 255, 255, 0.2);
  border-radius: 100rem;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.notification {
  scroll-behavior: smooth;
  overflow-y: auto;

  .notification-list {
    .notification-item {
      align-items: center;
      border-bottom: thin solid #f0f0f0;
      border-radius: 4px;
      cursor: pointer;

      &:hover {
        background-image: var(--color-gradient-normal);
      }

      &:last-child {
        border-bottom: 0;
      }
    }

    .notification-content {
      padding: 0 10px;
      .notification-message {
        font-size: var(--font-size-large);
      }
      .notification-time {
        font-size: var(--font-size-large);
      }
    }

    .notification-title {
      font-size: var(--font-size-large);
      font-weight: 600;
    }

    .notification-status {
      margin-right: 8px;

      .ant-badge-status-dot {
        width: 10px;
        height: 10px;
      }
    }
  }
}
