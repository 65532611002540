.notification {
  &__list {
    scroll-behavior: smooth;
    overflow-y: auto;
    height: 50vh;
  }

  &__item {
    align-items: center;
    border-bottom: thin solid #f0f0f0;
    border-radius: 4px;
    cursor: pointer;

    &:hover {
      background-image: var(--color-gradient-normal);
    }

    &:last-child {
      border-bottom: 0;
    }
  }

  &__item-inner {
    padding: 0 10px;
  }

  &__item-message,
  &__item-time {
    font-size: var(--font-size-large);
  }

  &__item-status {
    margin-right: 8px;

    .ant-badge-status-dot {
      width: 10px;
      height: 10px;
    }
  }

  &__icon {
    width: 32px;
    height: 32px;
    display: flex;
    background: rgba(255, 255, 255, 0.2);
    border-radius: 100rem;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }
}
