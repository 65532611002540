.sub-header {
  height: var(--sub-header-height);
  background: transparent;
  padding-inline: 22px;
  padding-block: 10px;

  h3 {
    margin-bottom: 0 !important;
  }
}
