.search {
  // background: var(--color-gradient-dark);
  border-radius: 4px;
  height: 40px;
  // Override css
  .ant-input-wrapper {
    height: 100%;
  }
  .ant-input {
    background: var(--color-gradient-dark-50);
    color: #fff;
    border: none;
    height: 100%;
    border-radius: 4px;
    font-size: var(--font-size-small);
    padding: 4px 14px;
    font-weight: 500;
  }
  .ant-input-search-button {
    border: none;
    background: transparent;
    color: white !important;
  }
  .ant-input:focus {
    border: none;
    box-shadow: none;
  }
  .ant-input::placeholder {
    color: #fff;
    font-weight: 500;
    font-size: var(--font-size-small);
  }
}
