.custom-card {
  min-width: 350px;
  width: 450px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  border-radius: 10px;
  button {
    min-width: 160px;
    margin-bottom: 20px;
  }
  &.v-card {
    .description {
      padding: 20px;
    }
  }
  &.h-card {
    padding: 20px;
    button {
      min-width: 100px;
      margin-bottom: 20px;
    }
  }
  &.hoverable {
    transition: scale ease 0.2s;
    cursor: pointer;
    &:hover {
      scale: 1.05;
    }
  }
  &.disabled {
    cursor: not-allowed;
  }

  .action-container {
    button {
      margin-bottom: 0;
    }
  }
}
