:where(.css-dev-only-do-not-override-kt682q).ant-breadcrumb .ant-breadcrumb-separator {
  margin-inline: 6px;
}
.breadcrumb-container {
  padding-block: 4px;
  padding-inline: 10px;
  // border-bottom: thin solid var(--divider-color);
  z-index: 10;
  .breadcrumb {
    &:hover {
      cursor: default;
    }

    .breadcrumb-item {
      font-weight: 600;
      &:hover {
        cursor: pointer;
        color: var(--color-link);
      }
    }
  }
}
