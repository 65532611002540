.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: var(--color-gradient-dark);
  color: #fff;
  height: 50px;
  padding: 0 20px;

  &__brand {
    display: flex;
    align-items: center;
    gap: 20px;
    margin-left: 30px;
  }

  &__brand-logo {
    cursor: pointer;
    width: var(--header-logo-width);
    filter: brightness(0) invert(1);
  }

  &__brand-title {
    font-size: 16px;
    font-weight: 600;
    color: #fff;
    text-transform: uppercase;
  }

  &__act {
    display: flex;
    align-items: center;
    gap: 20px;
  }

  &__act-add {
    @keyframes shiny-glass {
      0% {
        -webkit-transform: scale(0) rotate(45deg);
        opacity: 0;
      }

      80% {
        -webkit-transform: scale(0) rotate(45deg);
        opacity: 0.5;
      }

      81% {
        -webkit-transform: scale(4) rotate(45deg);
        opacity: 1;
      }

      100% {
        -webkit-transform: scale(50) rotate(45deg);
        opacity: 0;
      }
    }

    position: relative;
    display: flex;
    justify-content: center;
    background-color: rgba(255, 255, 255, 0.2);
    color: #fff;
    gap: 10px;
    padding: 16px;
    margin-right: 20px;
    border: none;
    align-items: center;
    border-radius: var(--radius-normal);
    overflow: hidden;

    &:before {
      position: absolute;
      content: '';
      display: inline-block;
      top: -180px;
      left: 0;
      width: 30px;
      height: 100%;
      background-color: #fff;
      animation: shiny-glass 3.5s ease-in-out infinite;
    }

    &-icon {
      height: 18px;
    }

    span {
      color: var(--color-button);
      font-size: var(--font-size-medium);
      font-weight: var(--font-weight-normal);
      line-height: 1.5;
    }
  }

  &__act-auth {
    display: flex;
    align-items: center;
    gap: 10px;
    height: 32px;
    display: flex;
    background: rgba(255, 255, 255, 0.2);
    border-radius: var(--radius-normal);
    padding: 8px;
    color: #fff;
    cursor: pointer;
  }

  &__act-auth-left {
    display: flex;
    align-items: center;
    gap: 10px;

    height: 100%;
  }

  &__act-auth-name {
    color: inherit;
    font-weight: 600;
  }

  &__act-auth-item {
    display: flex;
    gap: 10px;
    padding: 10px;
  }

  &__act-auth-item-title {
    font-size: var(--font-size-large);
    font-weight: 600;
  }
}
