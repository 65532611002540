.app-menu-drawer {
  .ant-drawer-body {
    padding: 0 !important;
    width: 100vw;
    height: 100vh;
    position: relative;
    overflow: hidden;
  }

  & img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .app-menu-container {
    background: rgba(0, 0, 0, 0.2); /* Black background with 0.5 opacity */
    color: #f1f1f1; /* Grey text */
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    .app-menu {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);

      width: 900px;

      &-title {
        color: #f1f1f1;
        margin-bottom: 0;
        margin-top: 40px;
      }

      .root-app-menu {
        background: rgba(0, 0, 0, 0.4);
        border-radius: 8px;
      }

      .children-app {
        min-height: 300px;
      }

      .app-item {
        height: 150px;
        border-radius: 8px;
        cursor: pointer;
        padding-block: 20px;
        position: relative;

        // transition: background 0.2s ease-in-out;

        // .thumbnail-icon {
        //   margin-top: 20px;
        // }
        &:hover {
          background: rgba(0, 0, 0, 0.2);
        }

        &-text {
          color: #f1f1f1;
        }

        .current-arrow {
          position: absolute;
          bottom: 0;
          color: #e5ec30ba;
          font-size: 20px;
        }
      }

      .ant-menu {
        background-color: transparent;
      }

      &__list {
        height: 500px;
        margin-top: 20px;
        overflow-y: auto;
        scrollbar-gutter: stable;

        .ant-menu-title-content {
          margin-left: 10px;
        }
      }
    }
  }
}
