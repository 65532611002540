.raise-ticket-modal {
  .ant-modal-title {
    .back-btn {
      margin-inline-start: -8px;
      &:hover {
        background-color: unset;
        transform: translateX(-2px);
      }
    }
  }
  .ant-modal-content {
    width: 600px !important;
    min-height: 300px;
    .card-row {
      margin: 20px;
      .card {
        padding: 20px;
        box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
        border-radius: 10px;
        cursor: pointer;
        transition: scale ease 0.2s;
        &:hover {
          scale: 1.05;
        }
      }
    }
  }
}
