.unit-of-currency {
  .ant-select-item:has(.disable) {
    &:hover {
      background-color: unset !important;
    }
    cursor: default;
    user-select: none;
    opacity: 0.5;
    pointer-events: none;
  }
}
