.sidebar {
  height: calc(100vh - var(--header-height));
  overflow-y: auto;
  box-shadow: 0 1px 4px #00000029;
  // border-right: var(--divider-size) solid var(--divider-color);
  z-index: 100;
  padding: 20px 10px;

  &__header {
    background-color: #fff;
    padding: 10px;
    position: sticky;
    top: 0;
    z-index: 1000;
  }

  &__title {
    height: 32px;

    background: var(--color-gradient-normal);
    box-shadow: 0 1px 4px #00000029;
    border-radius: 6px;
    span {
      color: #000;
    }
  }

  &__menu {
    border-right: 0;
    min-height: 100px;
    padding-bottom: 20px;
  }
}
